import React from "react"
import Project from "../components/project"
import Layout from "../components/layout"
import SEO from "../components/seo"
import './index.scss'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className='home-intro-container'>
      <div className='home-heading'>
        <h1>Developer</h1>
        <h1>Problem Solver</h1>
        <h1>Curious</h1>
      </div>
      <div className='home-summary'>
        <p>Hi, I'm Taqwa.</p>
        <p>I build sleek and performant websites and applications for a diverse set of brands.</p>
        <p>I'm an armchair beekeeper, and I love zombie-themed fiction, skincare, and Afrofuturism.</p>
        <p>Fluent in Arabic and Sarcasm. Catch me panic reading library eBook loans 3 hours before they're due. <span role="img" aria-label="Stack of books">📚</span></p>
      </div>
    </section>
    <section className="projects">
      <h1 className="projects-title">Select Work</h1>
      <ul className="project-grid">
        <Project 
          projectLink="/work/ppfa-crisis-courts"
          projectImageURL="/work/images/crisis-in-courts-1.jpg"
          projectImageAlt="Crisis in The Courts card / site homepage screenshot"
          projectTitle="Crisis in The Courts"
        />
        <Project 
          projectLink="/work/unfinished-live"
          projectImageURL="/work/images/unfinished-live-1.jpg"
          projectImageAlt="Unfinished Live card / site homepage screenshot"
          projectTitle="Unfinished Live"
        />
        <Project 
          projectLink="/work/aamc-action"
          projectImageURL="/work/images/aamc-action-1.jpg"
          projectImageAlt="AAMC Action card / site homepage screenshot"
          projectTitle="AAMC Action"
        />
      </ul>
    </section>
  </Layout>
)

export default IndexPage
