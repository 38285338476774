import React from 'react';
import '../components/project.scss'

const Project = (props) => (
  <li className="project-grid__item">
    <div className="project-item__detail">
      <a href={props.projectLink} className="project-item__link">
        <figure className="project-item__img">
          <img src={props.projectImageURL} alt={props.projectImageAlt}/>
          <figcaption className="project-item__img-caption">
            <span className="project-item__title">{props.projectTitle}</span>
          </figcaption>
        </figure>
      </a>
    </div>
  </li>
)

export default Project;
